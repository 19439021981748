import React from "react"
import ContentLoader from "react-content-loader";

export const Skeleton = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={43}
        viewBox="0 0 100 43"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        preserveAspectRatio="none"
        {...props}
    >
        <rect x="238" y="117" rx="3" ry="3" width="67" height="11"/>
        <rect x="64" y="42" rx="0" ry="0" width="1" height="0"/>
        <rect x="0" y="0" rx="0" ry="0" width="100" height="43"/>
    </ContentLoader>
)

