import {useContext} from "react";
import {Context} from "../../../App";


export const Category = ({category}) => {

    const {id, title, icon, description} = category;


    const {categoryHandler} = useContext(Context);


    return (
        <div className="sidebar__item category">
            <img src={icon} alt={description}/>
            <input type="checkbox" value={id} id={id} onChange={categoryHandler}/>
            <label htmlFor={id} className="category__title">
                {title}
            </label>
        </div>
    )
}