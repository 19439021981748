import {CardNews} from "../components/CardNews";
import {useContext} from "react";
import {Context} from "../App";


import '../components/CardNews/card-news.scss';
import {NewsModal} from "../components/NewsModal";
import {NewsPreloader} from "../components/CardNews/NewsPreloader";


export const News = () => {
    const {news, dataModal, modalOpen, isLoading} = useContext(Context);

    return (
        <>
            <section className="news">
                <div className="container news__container">
                    <h1 className="title news__title">Новости</h1>
                    <div className="news__items">
                        {isLoading ? [...new Array(4)].map((_, ind) => <NewsPreloader key={ind}/>)
                            : news.map(data => {
                                return <CardNews key={data.id} {...data}/>
                            })
                        }
                    </div>
                </div>
            </section>
            <NewsModal dataModal={dataModal} modalOpen={modalOpen}/>
        </>
    )
}