import "./header.scss";
import logo from "./img/logo.svg";
import searchIcon from "../../assets/img/icons/search.svg";
import locationIcon from "../../assets/img/icons/map.svg";
import logoPeople from "./img/logo-people.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../App";

import links from "./links.json";

export const Header = () => {
  const { modeHandler, modeDisplay, searchModalHandler, locationHandler } = useContext(Context);

  const { pathname } = useLocation();

  return (
    <header className={!modeDisplay ? "header" : "header header-map"}>
      <div className="container">
        {pathname !== "/nonheader" && (
          <div className="header__body">
            <div className="header__logos">
              <Link to="/" className="header__logo">
                <img src={logo} alt="logo" />
              </Link>
              <div className="header__logo">
                <img src={logoPeople} alt="logo" />
              </div>
            </div>
            <nav className="header__nav">
              <ul className="header__list">
                {links.map((item, ind) => {
                  return (
                    <li key={ind} className="header__item">
                      <NavLink to={item.path}>{item.title}</NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        )}

        {(pathname === "/" || pathname === "/nonheader") && (
          <div className="header__body_mobile">
            <button className="header__search-btn" onClick={searchModalHandler}>
              <img src={searchIcon} alt="Поиск" />
            </button>
            <div className="sidebar__mode">
              <div className="sidebar__list-mode">
                <button
                  onClick={modeHandler}
                  className={
                    modeDisplay ? "sidebar__btn-mode" : "sidebar__btn-mode sidebar__btn-mode_active"
                  }
                >
                  Список
                </button>
                <button
                  className={
                    !modeDisplay
                      ? "sidebar__btn-mode sidebar__btn-mode-map"
                      : "sidebar__btn-mode sidebar__btn-mode-map sidebar__btn-mode_active"
                  }
                  onClick={modeHandler}
                >
                  Карта
                </button>
              </div>
            </div>
            <button className="header__location-btn" onClick={locationHandler}>
              <img src={locationIcon} alt="Место" />
            </button>
          </div>
        )}
      </div>
    </header>
  );
};
