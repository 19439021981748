import React, { useContext } from "react";
import { Context } from "../../App";
import "./search-modal.scss";
import { SearchCategory } from "../Sidebar/Category/SearchCategory";
import { Category } from "../Sidebar/Category";
import { HistorySearch } from "./HistorySearch";

export const SearchModal = () => {
  const {
    isEmpty,
    search,
    searchMobile,
    ref,
    searchHandler,
    searchModalHandler,
    focusHandler,
    partners,
    countSearch,
    categories,
  } = useContext(Context);

  return (
    <div
      className={!searchMobile ? "search-modal" : "search-modal search-modal_active"}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="search-modal__container" ref={ref}>
        <div className="search-modal__body">
          <div className="sidebar__search-block">
            <div className="sidebar__search">
              <input
                type="search"
                value={search}
                onChange={searchHandler}
                onFocus={focusHandler}
                placeholder="Поиск"
              />
            </div>
            <button type="button" className="sidebar__reset" onClick={searchModalHandler}>
              Отменить
            </button>
          </div>
          <div className="search-mobile_category">
            {isEmpty ? (
              localStorage.getItem("history") ? (
                <div className="sidebar__empty">
                  {JSON.parse(localStorage.getItem("history")).map((category, ind) => {
                    return <HistorySearch key={ind} category={category} />;
                  })}
                </div>
              ) : (
                <div className="sidebar__empty search">
                  <h3 className="search__title">История поиска пуста</h3>
                  <p className="search__description">
                    Здесь будет храниться история ваших поисковых запросов
                  </p>
                </div>
              )
            ) : search ? (
              countSearch === 0 ? (
                <div className="sidebar__empty search">
                  <h3 className="search__title"> Ничего не найдено :(</h3>
                  <p className="search__description">Попробуйте ввести другой запрос</p>
                </div>
              ) : (
                partners
                  .filter(
                    (el) =>
                      (el.title && el.title.toLowerCase().search(search.toLowerCase()) !== -1) ||
                      (el.name && el.name.toLowerCase().search(search.toLowerCase()) !== -1),
                  )
                  .map((partner) => {
                    return <SearchCategory key={partner.id} partner={partner} />;
                  })
              )
            ) : (
              <>
                {categories.map((category) => {
                  return <Category key={category.id} category={category} />;
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
