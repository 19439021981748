export const getCategory = async (url) => {
    try {
        const res = await fetch(`https://api.card-rt.ru/category/category`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=UTF-8 ",
                accept: "application/json"
            },

        });
        if (!res.ok) {
            throw new Error(`Возникла ошибка ${res.status}`);
        }
        return await res.json();
    } catch (e) {
        console.error(e);
    }
}
export const getPartners = async () => {
    try {
        const res = await fetch(`https://api.card-rt.ru/lkb/lkb/get-partners`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=UTF-8 ",
                accept: "application/json"
            },

        });
        if (!res.ok) {
            throw new Error(`Возникла ошибка ${res.status}`);
        }
        return await res.json();
    } catch (e) {
        console.error(e);
    }
}


export const getNews = async () => {
    try {
        const res = await fetch("https://api.card-rt.ru/news/news", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=UTF-8 ",
                accept: "application/json"
            },
        });
        if (!res.ok) {
            throw new Error(`Возникла ошибка ${res.status}`);
        }
        return await res.json();
    } catch (e) {
        console.error(e);
    }
}
