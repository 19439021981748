import { Clusterer, GeolocationControl, Map, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import "./map.scss";
import { useContext } from "react";
import { Context } from "../../App";
import { Location } from "./Location";

export const YandMap = (props) => {
  const { partners, selectFilter, city, map } = useContext(Context);
  const clusterOptions = {
    preset: "islands#grayCircleIcon",
    groupByCoordinates: false,
    clusterDisableClickZoom: false,
    clusterHideIconOnBalloonOpen: false,
    geoObjectHideIconOnBalloonOpen: false,
  };

  return (
    <YMaps
      query={{
        apikey: "fc46c0f5-ce69-4936-95d0-d75ad848c4da",
      }}
    >
      <div className="map">
        <Map
          defaultState={city}
          width={100 + "%"}
          height={100 + "vh"}
          instanceRef={map}
          modules={[
            "templateLayoutFactory",
            "layout.ImageWithContent",
            "Placemark",
            "geocode",
            "geoObject.addon.balloon",
            "geoObject.addon.hint",
            "GeoObjectCollection",
            "geolocation",
            "geocode",
          ]}
        >
          <Clusterer options={clusterOptions}>
            {!selectFilter.length > 0
              ? partners.map((partner, ind) => <Location key={ind} partner={partner} />)
              : selectFilter.map((partner) => <Location key={partner.id} partner={partner} />)}
            {/* Creating a geo object with the "Point" geometry type. */}
          </Clusterer>
          <ZoomControl options={{ size: "small", position: { bottom: 226, right: 16 } }} />
          <GeolocationControl options={{ position: { bottom: 50, right: 16 } }} />
        </Map>
      </div>
    </YMaps>
  );
};
