import React from "react"
import ContentLoader from "react-content-loader"

export const CardSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={230}
        viewBox="0 0 100 230"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        preserveAspectRatio="none"
        {...props}
    >
        <rect x="64" y="42" rx="0" ry="0" width="1" height="0"/>
        <rect x="0" y="0" rx="0" ry="0" width="452" height="176"/>
        <rect x="-2" y="183" rx="0" ry="0" width="452" height="27"/>
        <rect x="0" y="215" rx="0" ry="0" width="452" height="14"/>
    </ContentLoader>
)
