import React, {useContext} from "react";
import {Context} from "../../App";

import backup from '../../assets/img/icons/time.svg';

export const HistorySearch = ({category}) => {
    const {modalOpen, partners} = useContext(Context);

    let partnerCard = partners.filter(el => el?.title && el.title.toLowerCase() === category.toLowerCase() || el?.name && el?.name?.toLowerCase() === category.toLowerCase());
    return (
        <>

            <div className="sidebar__item category history" onClick={() => modalOpen(partnerCard[0])}>
                <div className="category__image">
                    <img src={backup} alt={category}/>
                </div>
                <div className="category__title">
                    {category}
                </div>
            </div>
        </>

    )
}