import '../PlaceModal/place-modal.scss';
import {useContext} from "react";
import {Context} from "../../App";
import close from '../../assets/img/icons/close.svg'
import './news-modal.scss';

export const NewsModal = ({dataModal, modalOpen}) => {

    const {modal, refMouse} = useContext(Context);
    const {title, date, icon, id, text} = dataModal;

    return (
        <section className={!modal ? "place-modal news-modal " : "place-modal news-modal active"}>
            <div className="place-modal__body news-modal__body">

                <div className="place-modal__content news-modal__content" ref={refMouse}>
                    {dataModal && <>
                        {icon && (<div className="place-modal__image news-modal__image">
                            <img src={icon} alt={title}/>
                        </div>)}
                        <div className="place-modal__info news-modal__info">
                            {date && <time className="news-modal__date">{date}</time>}
                            {title && <h3 className="place-modal__title news-modal__title">{title}</h3>}
                            {text && (<div className="place-modal__description news-modal__description"
                                           dangerouslySetInnerHTML={{__html: text}}>
                            </div>)}

                            <button className="place-modal__close news-modal__close" onClick={modalOpen}>
                                <img src={close} alt="closed"/>
                            </button>
                        </div>

                    </>}
                </div>
            </div>
        </section>
    )
}