import React from "react"
import ContentLoader from "react-content-loader"

export const NewsPreloader = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={397}
        viewBox="0 0 100 397"
        preserveAspectRatio="none"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="64" y="42" rx="0" ry="0" width="1" height="0"/>
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="190"/>
        <rect x="0" y="217" rx="0" ry="0" width="100%" height="15"/>
        <rect x="0" y="199" rx="0" ry="0" width="65" height="13"/>
        <rect x="0" y="240" rx="0" ry="0" width="246" height="15"/>
        <rect x="0" y="265" rx="0" ry="0" width="100%" height="14"/>
        <rect x="0" y="285" rx="0" ry="0" width="288" height="14"/>
        <rect x="0" y="308" rx="0" ry="0" width="212" height="14"/>
    </ContentLoader>
)
