import "./sidebar.scss";
import React, { useContext, useRef } from "react";
import { Context } from "../../App";
import { Category } from "./Category";
import { SearchCategory } from "./Category/SearchCategory";
import { Skeleton } from "./Category/Skeleton";
import { HistorySearch } from "../SearchModal/HistorySearch";
import { useCategoryClickOutside } from "../../hook/useCategoryClickOutside";

export const Sidebar = () => {
  const {
    modeDisplay,
    modeHandler,
    focusHandler,
    search,
    isEmpty,
    setIsEmpty,
    searchHandler,
    categories,
    partners,
    countSearch,
    isLoading,
  } = useContext(Context);
  const categoryRef = useRef(null);

  useCategoryClickOutside(categoryRef, () => {
    if (window.innerWidth > 996) {
      setIsEmpty(false);
    }
  });
  return (
    <aside className={!modeDisplay ? "sidebar" : "sidebar sidebar-map"}>
      <div className="sidebar__mode">
        <div className="sidebar__list-mode">
          <button
            onClick={modeHandler}
            className={
              modeDisplay ? "sidebar__btn-mode" : "sidebar__btn-mode sidebar__btn-mode_active"
            }
          >
            Список
          </button>
          <button
            className={
              !modeDisplay
                ? "sidebar__btn-mode sidebar__btn-mode-map"
                : "sidebar__btn-mode sidebar__btn-mode-map sidebar__btn-mode_active"
            }
            onClick={modeHandler}
          >
            Карта
          </button>
        </div>
      </div>
      <div className="sidebar__category" ref={categoryRef}>
        <div className="sidebar__search">
          <input
            type="search"
            value={search}
            onChange={searchHandler}
            onFocus={focusHandler}
            placeholder="Поиск"
          />
        </div>
        {/* {!modeDisplay && <h1 className="title-page">Акции</h1>} */}
        <div className="sidebar__list">
          {isEmpty ? (
            localStorage.getItem("history") ? (
              <div className="sidebar__empty">
                {JSON.parse(localStorage.getItem("history")).map((category, ind) => {
                  return <HistorySearch key={ind} category={category} />;
                })}
              </div>
            ) : (
              <div className="sidebar__empty search">
                <h3 className="search__title">История поиска пуста</h3>
                <p className="search__description">
                  Здесь будет храниться история ваших поисковых запросов
                </p>
              </div>
            )
          ) : search ? (
            countSearch === 0 ? (
              <div className="sidebar__empty search">
                <h3 className="search__title"> Ничего не найдено :(</h3>
                <p className="search__description">Попробуйте ввести другой запрос</p>
              </div>
            ) : (
              partners
                .filter(
                  (el) =>
                    (el.title && el.title.toLowerCase().search(search.toLowerCase()) !== -1) ||
                    (el.name && el.name.toLowerCase().search(search.toLowerCase()) !== -1),
                )
                .map((partner) => {
                  return <SearchCategory key={partner.id} partner={partner} />;
                })
            )
          ) : (
            <>
              {isLoading
                ? [...new Array(6)].map((_, ind) => <Skeleton key={ind} />)
                : categories.map((category) => {
                    return <Category key={category.id} category={category} />;
                  })}
            </>
          )}
        </div>
      </div>
    </aside>
  );
};
