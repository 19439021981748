import { Placemark } from "@pbe/react-yandex-maps";
import { useContext, useRef } from "react";
import { Context } from "../../App";

export const Location = ({ partner }) => {
  const { modalOpen } = useContext(Context);
  const { lat, lon, name, title, category, address } = partner;
  const ref = useRef();
  let ymaps = window.ymaps;

  // const handleChange = () => {
  //   console.log(1);
  // };

  return (
    <Placemark
      //   onClick={handleChange}
      instanceRef={ref}
      geometry={{
        type: "Point",
        coordinates: address[0]?.coords
          ? [address[0]?.coords?.lat, address[0]?.coords?.lon]
          : [lat, lon],
      }}
      properties={{
        // The placemark content.
        iconContent: `<div class="map__location">${title || name}</div>`,
      }}
      onClick={() => {
        setTimeout(() => {
          modalOpen(partner);
        }, 0);
      }}
      // Options.
      options={{
        iconLayout: "default#imageWithContent",
        iconImageHref: category?.icon,
        iconImageSize: [14, 14],
        iconImageOffset: [21, 0],
      }}
    />
  );
};
