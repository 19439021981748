import "./cards.scss";
import { useContext } from "react";
import { Context } from "../../App";
import { CardSkeleton } from "./CardSkeleton";
import { Card } from "./Card";

export const Cards = () => {
  const { selectFilter, partners, isLoading } = useContext(Context);
  return (
    <>
      <div className="home__cards">
        <h2 className="home__title title">Список партнеров</h2>
        <p className="home__description">
          Лучшие предложения от партнеров Карты Жителя Республики Татарстан
        </p>
        <div className="home__items">
          {isLoading
            ? [...new Array(4)].map((_, ind) => <CardSkeleton key={ind} />)
            : !selectFilter.length > 0
            ? partners.map((partner, ind) => {
                return <Card key={ind} partner={partner} />;
              })
            : selectFilter.map((partner) => {
                return <Card key={partner.id} partner={partner} />;
              })}
        </div>
      </div>
    </>
  );
};
