import close from "../../assets/img/icons/close.svg";
import "./city-modal.scss";
import { useContext } from "react";
import { Context } from "../../App";

import data from "./data.json";
import { useClickOutside } from "../../hook/useClickOutside";

export const CityModal = () => {
  const { locationHandler, locationMob, onCityClick, getMyLocation } = useContext(Context);

  return (
    <div
      className={!locationMob ? "city-modal" : "city-modal active"}
      onClick={() => {
        if (document.body.classList.contains("noscroll")) {
          document.body.classList.remove("noscroll");
        }
      }}
    >
      <div className="city-modal__body" onClick={locationHandler}>
        <div className="city-modal__content" onClick={(e) => e.stopPropagation()}>
          <div className="city-modal__top">
            <h4 className="city-modal__title">Города</h4>

            <button className="place-modal__close" onClick={locationHandler}>
              <img src={close} alt="closed" />
            </button>
          </div>

          <div className="city-modal__list">
            <div className="city-modal__item city" onClick={getMyLocation}>
              <input type="radio" name="city" id="all" />
              <label htmlFor="all" className="city__name category__title">
                Все рядом со мной
              </label>
            </div>
            {data.map((item, ind) => (
              <div key={ind} className="city-modal__item city" onClick={onCityClick}>
                <input type="radio" name="city" id={item.coords} />
                <label htmlFor={item.coords} className="city__name">
                  {item.data.content}
                </label>
              </div>
            ))}

            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="kaz" id="kaz"/>*/}
            {/*    <label htmlFor="kaz" className="city__name">*/}
            {/*        Казань*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="azn" id="azn"/>*/}
            {/*    <label htmlFor="azn" className="city__name">*/}
            {/*        Азнакаево*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="arsc" id="arsc"/>*/}
            {/*    <label htmlFor="arsc" className="city__name">*/}
            {/*        Арск*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="bav" id="bav"/>*/}
            {/*    <label htmlFor="bav" className="city__name">*/}
            {/*        Бавлы*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="bol" id="bol"/>*/}
            {/*    <label htmlFor="bol" className="city__name">*/}
            {/*        Болгар*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="bug" id="bug"/>*/}
            {/*    <label htmlFor="bug" className="city__name">*/}
            {/*        Бугульма*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="buibugbolbavarsc" id="bui"/>*/}
            {/*    <label htmlFor="bui" className="city__name">*/}
            {/*        Буинск*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*<div className="city-modal__item city">*/}
            {/*    <input type="checkbox" name="el" id="el"/>*/}
            {/*    <label htmlFor="el" className="city__name">*/}
            {/*        Елабуга*/}
            {/*    </label>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
