import React, {useCallback, useContext} from "react";
import {Context} from "../../../App";

export const SearchCategory = ({partner}) => {
    const {id, title, name} = partner;
    const {modalOpen, search} = useContext(Context);


    const colorCategory = {
        // backgroundColor:  partner.category.color,
        padding: '5px',
        display: 'flex',
        borderRadius: '50%'
    };
    const sizeImg = {
        width: '14px',
        height: '14px'
    }

    const showCategory = {
        display: 'flex'
    }

    const displayInline = {
        display: 'inline-flex'
    }
    const ActiveText = () => {
        if (!search) return title && title || name && name;
        const reg = new RegExp(search, 'ig');
        const matchValue = title && title.match(reg) || name && name.match(reg);
        if (matchValue && title) {
            return title.split(reg).map((string, ind, arr) => {
                if (ind < arr.length - 1) {
                    const sym = matchValue.shift();
                    return <div key={ind}>{string}<strong key={ind}>{sym}</strong></div>
                }
                return string;
            })
        }
        if (matchValue && name) {
            return name.split(reg).map((string, ind, arr) => {
                if (ind < arr.length - 1) {
                    const sym = matchValue.shift();
                    return <div key={ind}>{string}<strong key={ind}>{sym}</strong></div>
                }
                return string;
            })
        }

    }

    const textLight = useCallback(str => {
        return <ActiveText/>;
    }, [search])

    return (
        <>
            <div style={showCategory} className="sidebar__item category category_search"
                 onClick={() => modalOpen(partner, true)}>
                <div className="category__image" style={colorCategory}>
                    {partner.category && <img style={sizeImg} key={id} src={partner.category.icon} alt={title}/>}
                </div>
                <div className="category__title">
                    {textLight(title)}
                </div>
            </div>
        </>

    )
}