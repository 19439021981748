import {useContext} from "react";
import {Context} from "../../App";
import {useInView} from "react-intersection-observer";
import {NewsPreloader} from "./NewsPreloader";

export const CardNews = (news) => {
    const {modalOpen} = useContext(Context);
    const {title, date, icon, id, text} = news;
    const {ref, inView} = useInView({
        threshold: 0.5,
        triggerOnce: true
    })
    return (

        <div className="news__item" ref={ref} onClick={() => modalOpen(news)}>
            {inView ? icon && <>
                <div className="news__image">
                    <img src={icon} alt={title}/>
                </div>
                <div className="news__info">
                    {date && <time className="news__date">{date}</time>}
                    {title && <h2 className="news__title">{title}</h2>}
                    {text && <div className="news__description" dangerouslySetInnerHTML={{__html: text}}></div>}
                </div>
            </> : <NewsPreloader/>}
        </div>


    )
}