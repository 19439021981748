import { useContext } from "react";
import { Context } from "../../App";
import { useInView } from "react-intersection-observer";
import { CardSkeleton } from "./CardSkeleton";

export const Card = ({ partner }) => {
  const {
    name,
    background_image,
    percent_sale,
    category,
    scores,
    cashback,
    address,
    title,
    image,
    short_description,
    discount,
    city,
    description,
    lat,
    lng,
    work_time,
  } = partner;
  const { modalOpen, setIsDragging } = useContext(Context);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  return (
    <div className="home__item place-card" ref={ref} onClick={() => modalOpen(partner)}>
      {inView ? (
        <>
          <div className="place-card__block-image">
            {background_image && (
              <div className="place-card__image">
                <img src={background_image} alt={name} />
                {image && (
                  <img className="place-card__cat-img" src={image} alt={short_description} />
                )}
              </div>
            )}
            {percent_sale && <span className="place-card__bonus">-{percent_sale}%</span>}
            {discount && <span className="place-card__bonus">-{discount} ₽</span>}
            {scores && <span className="place-card__bonus place-card__bonus_point">{scores}</span>}
            {cashback && (
              <span className="place-card__bonus place-card__bonus_cash">Кешбэк {cashback}%</span>
            )}
          </div>
          <div className="place-card__info">
            {category?.title && <span className="place-card__category">{category.title}</span>}
            {name || (title && <h2 className="place-card__title">{name || title}</h2>)}
            {address.address && (
              <div className="place-card__location">
                {address.map((city, ind) => {
                  return (
                    <div className="place-card__address" key={ind}>
                      {city.address}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      ) : (
        <CardSkeleton />
      )}
    </div>
  );
};
