import { useEffect } from "react";

export const useClickOutside = (ref, callback) => {
  const clickClosedModal = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener("touchend", clickClosedModal, true);
    return () => {
      window.removeEventListener("touchend", clickClosedModal, true);
    };
  });
};
