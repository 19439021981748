import {Sidebar} from "../components/Sidebar";
import {Cards} from "../components/Cards";
import {YandMap} from "../components/YandMap";
import {useContext} from "react";
import {Context} from "../App";
import {SearchModal} from "../components/SearchModal";
import {CityModal} from "../components/CityModal";
import {PlaceModal} from "../components/PlaceModal";


export const Home = () => {
    const {modeDisplay, modalOpen, dataModal} = useContext(Context);

    return (
        <section className="home">
            <div className="container">
                <div className="home__inner">
                    <Sidebar/>
                    {!modeDisplay ? <Cards/> : <YandMap/>}
                    <PlaceModal dataModal={dataModal} modalOpen={modalOpen}/>
                    <SearchModal/>
                    <CityModal/>
                </div>
            </div>
        </section>
    )
}